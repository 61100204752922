<template>
  <div id="app">
    <navbar />
    <router-view />
    <my-footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import MyFooter from "@/components/_footer.vue";
export default {
  components: {
    Navbar,
    MyFooter
  },
}
</script>