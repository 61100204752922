<template>
  <b-navbar toggleable="lg" class = "py-4">
    <b-container>
      <b-navbar-brand href="#">
        <router-link :to="{ name: 'Home' }">
          
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              href="#home"
              :class="{ active: activeLink == 1 }"
              @click="activeLink = 1"
              >Home</b-nav-item
            >
            <b-nav-item
              href="#services"
              :class="{ active: activeLink == 2 }"
              @click="activeLink = 2"
              >our services</b-nav-item
            >
            <b-nav-item
              href="#clients"
              :class="{ active: activeLink == 3 }"
              @click="activeLink = 3"
              >Clints</b-nav-item
            >
            <b-nav-item
              href="#our-services"
              :class="{ active: activeLink == 4 }"
              @click="activeLink = 4"
              >Work</b-nav-item
            >
            
          </b-navbar-nav>
          <!-- <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown> -->
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      activeLink: 1,
    };
  },
  mounted(){
    window.addEventListener('scroll',()=>{
      if(window.scrollY > 100) {
        document.querySelector(".navbar").classList.add('bg-white' , 'text-dark')
      }else{
        document.querySelector(".navbar").classList.remove('bg-white')
      }
    })
  }
};
</script>
