<template>
  <div class="home">
    <section calss="popop">
      <b-modal id="popUp" size="lg" title="" hide-footer>
        <img src="../assets/images/service-6.jpg" class="rounded my-2" alt="" />
        <h1>Servises title</h1>
        <p class="lead">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium,
          aliquam ullam. Quisquam impedit rem debitis, repudiandae quas quod
          error ipsa voluptatibus eveniet ullam velit pariatur quia aperiam
          voluptatem? Cumque, ipsa?
        </p>
      </b-modal>
    </section>
    <header class="header">
      <b-container>
        <div class="header-text-box" data-aos="zoom-in">
          <h1>Welcome to <span>SOFT STORM</span> Technology</h1>
          <div class="scrolldown-wrapper mt-5">
            <div class="scrolldown">
              <svg height="30" width="10">
                <circle class="scrolldown-p1" cx="5" cy="15" r="2" />
                <circle class="scrolldown-p2" cx="5" cy="15" r="2" />
              </svg>
            </div>
          </div>
        </div>
        <div class="social-links">
          <a href="https://www.facebook.com" data-aos="fade-right">
            <img src="../assets/icons/facebook.svg" alt="" />
          </a>
          <a href="https://www.facebook.com" data-aos="fade-right">
            <img src="../assets/icons/instagram.svg" alt="" />
          </a>
          <a href="https://www.facebook.com" data-aos="fade-right">
            <img src="../assets/icons/twitter.svg" alt="" />
          </a>
        </div>
      </b-container>
    </header>

    <!-- services section -->
    <section class="services" id="services">
      <b-container>
        <div class="secondary-heading">
          <h2 data-aos="fade-up">take a look at our services</h2>
        </div>
        <div class="cards w-100">
          <b-row>
            <b-col sm="12" md="6" lg="3" class="mb-3">
              <div class="card w-100 h-100" data-aos="fade-up">
                <svg
                  height="512"
                  viewBox="0 0 60 60"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" fill="none" fill-rule="evenodd">
                    <g
                      id="085---Design-Software"
                      fill="#333"
                      fill-rule="nonzero"
                    >
                      <path
                        id="Shape"
                        d="m57 7h-1v-4c0-1.65685425-1.3431458-3-3-3h-46c-1.65685425 0-3 1.34314575-3 3v4h-1c-1.65685425 0-3 1.34314575-3 3v37c0 1.6568542 1.34314575 3 3 3h17.986c-.2211777 1.6029262-1.0372133 3.0640083-2.286 4.093h-.7c-1.6054918 0-2.907 1.3015082-2.907 2.907s1.3015082 2.907 2.907 2.907h24c1.6054918 0 2.907-1.3015082 2.907-2.907s-1.3015082-2.907-2.907-2.907h-.7c-1.2487867-1.0289917-2.0648223-2.4900738-2.286-4.093h17.986c1.6568542 0 3-1.3431458 3-3v-37c0-1.65685425-1.3431458-3-3-3zm-51 3h48v28c0 .5522847-.4477153 1-1 1h-46c-.55228475 0-1-.4477153-1-1zm0-7c0-.55228475.44771525-1 1-1h46c.5522847 0 1 .44771525 1 1v5h-48zm37.092 54c.0000003.6032569-.4887433 1.0924481-1.092 1.093h-24c-.6036472 0-1.093-.4893528-1.093-1.093s.4893528-1.093 1.093-1.093h24c.6032567.0005519 1.0920003.4897431 1.092 1.093zm-4.316-2.907h-17.552c.8570281-1.2141922 1.4048695-2.6191516 1.596-4.093h14.36c.1911305 1.4738484.7389719 2.8788078 1.596 4.093zm18.224-6.093h-54c-.55228475 0-1-.4477153-1-1v-2h4c.55228475 0 1-.4477153 1-1s-.44771525-1-1-1h-4v-33c0-.55228475.44771525-1 1-1h1v29c0 1.6568542 1.34314575 3 3 3h46c1.6568542 0 3-1.3431458 3-3v-29h1c.5522847 0 1 .44771525 1 1v33h-48c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1h48v2c0 .5522847-.4477153 1-1 1z"
                      />
                      <circle id="Oval" cx="51" cy="5" r="1" />
                      <circle id="Oval" cx="47" cy="5" r="1" />
                      <circle id="Oval" cx="43" cy="5" r="1" />
                      <path
                        id="Shape"
                        d="m11 4h-2c-.55228475 0-1 .44771525-1 1s.44771525 1 1 1h2c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1z"
                      />
                      <path
                        id="Shape"
                        d="m13.843 37h22.314c.7167676.0026186 1.3795057-.38062 1.7347675-1.0031564.3552619-.6225364.3480921-1.3880704-.0187675-2.0038436.042 0 .085.007.127.007 5.5009528.0049463 10.1598243-4.0545395 10.9077454-9.5044129.7479212-5.4498734-2.6450838-10.61437-7.9439914-12.091558s-10.8743734 1.1871485-13.053754 6.2379709l-1.233-1.9c-.3689119-.5676853-.9999751-.9101968-1.677-.9101968s-1.3080881.3425115-1.677.9101968l-11.157 17.168c-.3997291.6149492-.43079 1.3993647-.080937 2.0439954.349853.6446308 1.024489 1.0460515 1.757937 1.0460046zm24.157-23c4.9705627 0 9 4.0294373 9 9s-4.0294373 9-9 9c-.5028952-.0047693-1.0045615-.0505882-1.5-.137l-7.215-11.1c1.0365722-3.9689102 4.613003-6.7442861 8.715-6.763zm-13 3.835 2.369 3.645.007.01 8.781 13.51h-22.314z"
                      />
                    </g>
                  </g>
                </svg>
                <h3>Automation services</h3>
                <p>
                  Intelligent automation, like RPA (robotic process automation),
                  natural language processing and virtual agents, can help you
                  improve efficiency and productivity in many ways. But it
                  doesn't stop there.
                </p>
                <!-- <button class="btn text-dark">read more</button> -->
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3" class="mb-3">
              <div class="card w-100 h-100" data-aos="fade-up">
                <svg
                  height="512"
                  viewBox="0 0 60 60"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" fill="none" fill-rule="evenodd">
                    <g
                      id="085---Design-Software"
                      fill="#333"
                      fill-rule="nonzero"
                    >
                      <path
                        id="Shape"
                        d="m57 7h-1v-4c0-1.65685425-1.3431458-3-3-3h-46c-1.65685425 0-3 1.34314575-3 3v4h-1c-1.65685425 0-3 1.34314575-3 3v37c0 1.6568542 1.34314575 3 3 3h17.986c-.2211777 1.6029262-1.0372133 3.0640083-2.286 4.093h-.7c-1.6054918 0-2.907 1.3015082-2.907 2.907s1.3015082 2.907 2.907 2.907h24c1.6054918 0 2.907-1.3015082 2.907-2.907s-1.3015082-2.907-2.907-2.907h-.7c-1.2487867-1.0289917-2.0648223-2.4900738-2.286-4.093h17.986c1.6568542 0 3-1.3431458 3-3v-37c0-1.65685425-1.3431458-3-3-3zm-51 3h48v28c0 .5522847-.4477153 1-1 1h-46c-.55228475 0-1-.4477153-1-1zm0-7c0-.55228475.44771525-1 1-1h46c.5522847 0 1 .44771525 1 1v5h-48zm37.092 54c.0000003.6032569-.4887433 1.0924481-1.092 1.093h-24c-.6036472 0-1.093-.4893528-1.093-1.093s.4893528-1.093 1.093-1.093h24c.6032567.0005519 1.0920003.4897431 1.092 1.093zm-4.316-2.907h-17.552c.8570281-1.2141922 1.4048695-2.6191516 1.596-4.093h14.36c.1911305 1.4738484.7389719 2.8788078 1.596 4.093zm18.224-6.093h-54c-.55228475 0-1-.4477153-1-1v-2h4c.55228475 0 1-.4477153 1-1s-.44771525-1-1-1h-4v-33c0-.55228475.44771525-1 1-1h1v29c0 1.6568542 1.34314575 3 3 3h46c1.6568542 0 3-1.3431458 3-3v-29h1c.5522847 0 1 .44771525 1 1v33h-48c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1h48v2c0 .5522847-.4477153 1-1 1z"
                      />
                      <circle id="Oval" cx="51" cy="5" r="1" />
                      <circle id="Oval" cx="47" cy="5" r="1" />
                      <circle id="Oval" cx="43" cy="5" r="1" />
                      <path
                        id="Shape"
                        d="m11 4h-2c-.55228475 0-1 .44771525-1 1s.44771525 1 1 1h2c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1z"
                      />
                      <path
                        id="Shape"
                        d="m13.843 37h22.314c.7167676.0026186 1.3795057-.38062 1.7347675-1.0031564.3552619-.6225364.3480921-1.3880704-.0187675-2.0038436.042 0 .085.007.127.007 5.5009528.0049463 10.1598243-4.0545395 10.9077454-9.5044129.7479212-5.4498734-2.6450838-10.61437-7.9439914-12.091558s-10.8743734 1.1871485-13.053754 6.2379709l-1.233-1.9c-.3689119-.5676853-.9999751-.9101968-1.677-.9101968s-1.3080881.3425115-1.677.9101968l-11.157 17.168c-.3997291.6149492-.43079 1.3993647-.080937 2.0439954.349853.6446308 1.024489 1.0460515 1.757937 1.0460046zm24.157-23c4.9705627 0 9 4.0294373 9 9s-4.0294373 9-9 9c-.5028952-.0047693-1.0045615-.0505882-1.5-.137l-7.215-11.1c1.0365722-3.9689102 4.613003-6.7442861 8.715-6.763zm-13 3.835 2.369 3.645.007.01 8.781 13.51h-22.314z"
                      />
                    </g>
                  </g>
                </svg>
                <h3>Data analysis services</h3>
                <p>
                  we offer a gamut of data analysis services that precisely
                  cater to the specific requirements of the client.
                </p>
                <!-- <button class="btn text-dark">read more</button> -->
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3" class="mb-3">
              <div class="card w-100 h-100" data-aos="fade-up">
                <svg
                  height="512"
                  viewBox="0 0 60 60"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" fill="none" fill-rule="evenodd">
                    <g
                      id="085---Design-Software"
                      fill="#333"
                      fill-rule="nonzero"
                    >
                      <path
                        id="Shape"
                        d="m57 7h-1v-4c0-1.65685425-1.3431458-3-3-3h-46c-1.65685425 0-3 1.34314575-3 3v4h-1c-1.65685425 0-3 1.34314575-3 3v37c0 1.6568542 1.34314575 3 3 3h17.986c-.2211777 1.6029262-1.0372133 3.0640083-2.286 4.093h-.7c-1.6054918 0-2.907 1.3015082-2.907 2.907s1.3015082 2.907 2.907 2.907h24c1.6054918 0 2.907-1.3015082 2.907-2.907s-1.3015082-2.907-2.907-2.907h-.7c-1.2487867-1.0289917-2.0648223-2.4900738-2.286-4.093h17.986c1.6568542 0 3-1.3431458 3-3v-37c0-1.65685425-1.3431458-3-3-3zm-51 3h48v28c0 .5522847-.4477153 1-1 1h-46c-.55228475 0-1-.4477153-1-1zm0-7c0-.55228475.44771525-1 1-1h46c.5522847 0 1 .44771525 1 1v5h-48zm37.092 54c.0000003.6032569-.4887433 1.0924481-1.092 1.093h-24c-.6036472 0-1.093-.4893528-1.093-1.093s.4893528-1.093 1.093-1.093h24c.6032567.0005519 1.0920003.4897431 1.092 1.093zm-4.316-2.907h-17.552c.8570281-1.2141922 1.4048695-2.6191516 1.596-4.093h14.36c.1911305 1.4738484.7389719 2.8788078 1.596 4.093zm18.224-6.093h-54c-.55228475 0-1-.4477153-1-1v-2h4c.55228475 0 1-.4477153 1-1s-.44771525-1-1-1h-4v-33c0-.55228475.44771525-1 1-1h1v29c0 1.6568542 1.34314575 3 3 3h46c1.6568542 0 3-1.3431458 3-3v-29h1c.5522847 0 1 .44771525 1 1v33h-48c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1h48v2c0 .5522847-.4477153 1-1 1z"
                      />
                      <circle id="Oval" cx="51" cy="5" r="1" />
                      <circle id="Oval" cx="47" cy="5" r="1" />
                      <circle id="Oval" cx="43" cy="5" r="1" />
                      <path
                        id="Shape"
                        d="m11 4h-2c-.55228475 0-1 .44771525-1 1s.44771525 1 1 1h2c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1z"
                      />
                      <path
                        id="Shape"
                        d="m13.843 37h22.314c.7167676.0026186 1.3795057-.38062 1.7347675-1.0031564.3552619-.6225364.3480921-1.3880704-.0187675-2.0038436.042 0 .085.007.127.007 5.5009528.0049463 10.1598243-4.0545395 10.9077454-9.5044129.7479212-5.4498734-2.6450838-10.61437-7.9439914-12.091558s-10.8743734 1.1871485-13.053754 6.2379709l-1.233-1.9c-.3689119-.5676853-.9999751-.9101968-1.677-.9101968s-1.3080881.3425115-1.677.9101968l-11.157 17.168c-.3997291.6149492-.43079 1.3993647-.080937 2.0439954.349853.6446308 1.024489 1.0460515 1.757937 1.0460046zm24.157-23c4.9705627 0 9 4.0294373 9 9s-4.0294373 9-9 9c-.5028952-.0047693-1.0045615-.0505882-1.5-.137l-7.215-11.1c1.0365722-3.9689102 4.613003-6.7442861 8.715-6.763zm-13 3.835 2.369 3.645.007.01 8.781 13.51h-22.314z"
                      />
                    </g>
                  </g>
                </svg>
                <h3>Data center</h3>
                <p>
                  our experienced team delivers high-performance data center
                  installations, which range from small to large bespoke data
                  center designs
                </p>
                <!-- <button class="btn text-dark">read more</button> -->
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3" class="mb-3">
              <div class="card w-100 h-100" data-aos="fade-up">
                <svg
                  height="512"
                  viewBox="0 0 60 60"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" fill="none" fill-rule="evenodd">
                    <g
                      id="085---Design-Software"
                      fill="#333"
                      fill-rule="nonzero"
                    >
                      <path
                        id="Shape"
                        d="m57 7h-1v-4c0-1.65685425-1.3431458-3-3-3h-46c-1.65685425 0-3 1.34314575-3 3v4h-1c-1.65685425 0-3 1.34314575-3 3v37c0 1.6568542 1.34314575 3 3 3h17.986c-.2211777 1.6029262-1.0372133 3.0640083-2.286 4.093h-.7c-1.6054918 0-2.907 1.3015082-2.907 2.907s1.3015082 2.907 2.907 2.907h24c1.6054918 0 2.907-1.3015082 2.907-2.907s-1.3015082-2.907-2.907-2.907h-.7c-1.2487867-1.0289917-2.0648223-2.4900738-2.286-4.093h17.986c1.6568542 0 3-1.3431458 3-3v-37c0-1.65685425-1.3431458-3-3-3zm-51 3h48v28c0 .5522847-.4477153 1-1 1h-46c-.55228475 0-1-.4477153-1-1zm0-7c0-.55228475.44771525-1 1-1h46c.5522847 0 1 .44771525 1 1v5h-48zm37.092 54c.0000003.6032569-.4887433 1.0924481-1.092 1.093h-24c-.6036472 0-1.093-.4893528-1.093-1.093s.4893528-1.093 1.093-1.093h24c.6032567.0005519 1.0920003.4897431 1.092 1.093zm-4.316-2.907h-17.552c.8570281-1.2141922 1.4048695-2.6191516 1.596-4.093h14.36c.1911305 1.4738484.7389719 2.8788078 1.596 4.093zm18.224-6.093h-54c-.55228475 0-1-.4477153-1-1v-2h4c.55228475 0 1-.4477153 1-1s-.44771525-1-1-1h-4v-33c0-.55228475.44771525-1 1-1h1v29c0 1.6568542 1.34314575 3 3 3h46c1.6568542 0 3-1.3431458 3-3v-29h1c.5522847 0 1 .44771525 1 1v33h-48c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1h48v2c0 .5522847-.4477153 1-1 1z"
                      />
                      <circle id="Oval" cx="51" cy="5" r="1" />
                      <circle id="Oval" cx="47" cy="5" r="1" />
                      <circle id="Oval" cx="43" cy="5" r="1" />
                      <path
                        id="Shape"
                        d="m11 4h-2c-.55228475 0-1 .44771525-1 1s.44771525 1 1 1h2c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1z"
                      />
                      <path
                        id="Shape"
                        d="m13.843 37h22.314c.7167676.0026186 1.3795057-.38062 1.7347675-1.0031564.3552619-.6225364.3480921-1.3880704-.0187675-2.0038436.042 0 .085.007.127.007 5.5009528.0049463 10.1598243-4.0545395 10.9077454-9.5044129.7479212-5.4498734-2.6450838-10.61437-7.9439914-12.091558s-10.8743734 1.1871485-13.053754 6.2379709l-1.233-1.9c-.3689119-.5676853-.9999751-.9101968-1.677-.9101968s-1.3080881.3425115-1.677.9101968l-11.157 17.168c-.3997291.6149492-.43079 1.3993647-.080937 2.0439954.349853.6446308 1.024489 1.0460515 1.757937 1.0460046zm24.157-23c4.9705627 0 9 4.0294373 9 9s-4.0294373 9-9 9c-.5028952-.0047693-1.0045615-.0505882-1.5-.137l-7.215-11.1c1.0365722-3.9689102 4.613003-6.7442861 8.715-6.763zm-13 3.835 2.369 3.645.007.01 8.781 13.51h-22.314z"
                      />
                    </g>
                  </g>
                </svg>
                <h3>Cloud Services</h3>
                <p>
                  In collaboration with the ministry of communications in Iraq,
                  we are proud to offer the best and most reliable hosting
                  services in the country.
                </p>
                <!-- <button class="btn text-dark">read more</button> -->
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </section>
    <!-- end of services section -->

    <!-- section about -->
    <section class="about" id="about">
      <b-container>
        <div class="secondary-heading" data-aos="fade-up">
          <h2>about us</h2>
        </div>
        <div class="text-container">
          <p data-aos="zoom-in">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi,
            tincidunt in mauris sodales maecenas blandit. Risus montes, iaculis
            ac tincidunt in eget in viverra. Scelerisque ligula cursus eget
            ultrices eget quisque malesuada tristique semper.
          </p>
        </div>
        <!-- <div class="team">
          <div class="person">
            <img src="../assets/images/person-1.jpg" alt="" />
            <p class="person-name">Travis Hamilton</p>
            <p class="person-job">General Manager</p>
          </div>
          <div class="person">
            <img src="../assets/images/person-2.jpg" alt="" />
            <p class="person-name">Ethel Stone</p>
            <p class="person-job">Chief Commercial Officer</p>
          </div>
          <div class="person">
            <img src="../assets/images/person-3.jpg" alt="" />
            <p class="person-name">Francis Knight</p>
            <p class="person-job">Operations Director</p>
          </div>
          <div class="person">
            <img src="../assets/images/person-4.jpg" alt="" />
            <p class="person-name">Duane Freeman</p>
            <p class="person-job">Technical Manager</p>
          </div>
          <div class="person">
            <img src="../assets/images/person-5.jpg" alt="" />
            <p class="person-name">Sue Simmmons</p>
            <p class="person-job">CEO</p>
          </div>
        </div> -->
      </b-container>
    </section>
    <!-- end of section about -->
    <!-- section clients -->
    <section class="clients" id="clients">
      <b-container>
        <div class="secondary-heading">
          <h2 data-aos="fade-up">meet our clients</h2>
        </div>
        <div class="slider">
          <div class="arrow arrow-left" @click="next">
            <img src="../assets/icons/left.png" alt="" />
          </div>
          <div class="slider-container">
            <img
              class="laptop-img"
              src="../assets/images/clients-slider-bg.png"
              alt=""
            />
            <div class="slides">
              <div class="slides-track">
                <div class="slide">
                  <img
                    class="slide-img"
                    src="../assets/images/slide-1.png"
                    alt=""
                  />
                </div>
                <div class="slide">
                  <img
                    class="slide-img"
                    src="../assets/images/slide-2.png"
                    alt=""
                  />
                </div>
                <div class="slide">
                  <img
                    class="slide-img"
                    src="../assets/images/slide-3.png"
                    alt=""
                  />
                </div>
                <div class="slide">
                  <img
                    class="slide-img"
                    src="../assets/images/slide-4.png"
                    alt=""
                  />
                </div>
                <div class="slide">
                  <img
                    class="slide-img"
                    src="../assets/images/slide-5.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="arrow arrow-right" @click="prev">
            <img src="../assets/icons/right.png" alt="" />
          </div>
        </div>
      </b-container>
    </section>
    <!-- end of section clients -->
    <!-- section our services -->
    <section class="our-services" id="our-services">
      <b-container>
        <div class="secondary-heading">
          <h2 data-aos="fade-up">some of our services</h2>
        </div>
        <div class="filter">
          <button class="active" data-aos="fade-up">All</button>
          <button data-aos="fade-up">Mobile Apps</button>
          <button data-aos="fade-up">We Development and Design</button>
          <button data-aos="fade-up">Safty Solutons</button>
        </div>
        <b-row class="">
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-1.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-2.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-3.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-4.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-5.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-6.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-7.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-8.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-9.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-10.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-11.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3" class="mb-3">
            <div class="image-card" data-aos="fade-up" @click="showWorkInfo()">
              <img src="../assets/images/service-12.jpg" alt="" />
              <div class="image-card-overlay">
                <p>service name</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- end of section our services -->
    <section class="technologies">
      <div class="secondary-heading">
        <h2 data-aos="fade-up">OUR TECHNOLOGIES</h2>
      </div>
      <b-container>
        <template>
          <div data-aos="fade-up">
            <VueSlickCarousel v-bind="settings">
              <div>
                <img
                  src="../assets/images/tech_slider/php.svg"
                  class="mx-auto"
                  width="100"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/swift.svg"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/ts.png"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/php.svg"
                  class="mx-auto"
                  width="100"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/swift.svg"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/ts.png"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/php.svg"
                  class="mx-auto"
                  width="100"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/swift.svg"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/ts.png"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/php.svg"
                  class="mx-auto"
                  width="100"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/swift.svg"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
              <div>
                <img
                  src="../assets/images/tech_slider/ts.png"
                  class="mx-auto"
                  alt=""
                  width="100"
                />
              </div>
            </VueSlickCarousel>
          </div>
        </template>
      </b-container>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Home",
  data() {
    return {
      slide: 0,
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 990,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ],
      },
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    let slideTrack = document.querySelector(".slides-track");
    setInterval(() => {
      if (this.slide < 4) {
        this.slide++;
        slideTrack.style.transform = `translateX(-${this.slide * 18}rem)`;
      } else {
        this.slide = 0;
        slideTrack.style.transform = `translateX(-${this.slide * 18}rem)`;
      }
    }, 3000);
  },
  methods: {
    next() {
      if (this.slide < 4) {
        this.slide++;
        let slideTrack = document.querySelector(".slides-track");
        slideTrack.style.transform = `translateX(-${this.slide * 18}rem)`;
        console.log(this.slide, this.slide * 18);
      }
    },
    prev() {
      if (this.slide > 0) {
        let slideTrack = document.querySelector(".slides-track");
        slideTrack.style.transform = `translateX(-${this.slide * 18 - 18}rem)`;
        console.log(this.slide, this.slide * 18 - 18);
        this.slide--;
      }
    },
    showWorkInfo() {
      this.$bvModal.show("popUp");
    },
  },
};
</script>
